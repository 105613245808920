export default {
    "cooldown": undefined,
    "localizables": {
        description: {},
        name: {}
    },
    "description": undefined,
    "effect1": {
        "duration": undefined,
        "duration_upgrade": undefined,
        "duration_upgrade_type": undefined,
        "name": undefined,
        "value": undefined,
        "value_type": undefined,
        "value_upgrade": undefined,
        "value_upgrade_type": undefined
    },
    "effect2": {
        "duration": undefined,
        "duration_upgrade": undefined,
        "duration_upgrade_type": undefined,
        "name": undefined,
        "value": undefined,
        "value_type": undefined,
        "value_upgrade": undefined,
        "value_upgrade_type": undefined
    },
    "info_fields": [],
    "quest_upper_trophy_limit": 0,
    "index": undefined,
    "name": undefined,
    "rarity": undefined,
    "spell": undefined,
    "upgrade_cooldown": undefined,
    "upgrade_cooldown_type": undefined,
    "trophy_level": undefined
}