export default {
    RARITY: [
        {
            text: 'COMMON',
            value: 0
        },
        {
            text: 'RARE',
            value: 1
        },
        {
            text: 'EPIC',
            value: 2
        },
        {
            text: 'LEGENDARY',
            value: 3
        }
    ],
    GAME_STATUS: [
        {
            text: 'DISABLED',
            value: 0
        },
        {
            text: 'ENABLED',
            value: 1
        }
    ],
    VALUE_TYPE: [
        {
            text: 'NUMBER',
            value: 0
        },
        {
            text: 'PERCENTAGE',
            value: 1
        },
        {
            text: 'X-FACTOR',
            value: 2
        },
        {
            text: 'UNAFFECTED',
            value: 3
        }
    ],
    upgrade_types: [
        {
            text: 'UNAFFECTED',
            value: 3
        },
        {
            text: 'NUMBER',
            value: 0
        },
        {
            text: 'PERCENTAGE',
            value: 1
        }
    ],
    ICONS: [
        {
            text: 'DAMAGE',
            value: 0
        },
        {
            text: 'ATTACK_SPEED',
            value: 1
        },
        {
            text: 'TIMED_ATTRIBUTE',
            value: 2
        },
        {
            text: 'BUFF_ATTRIBUTE',
            value: 3
        }
    ],
    FIELD_VALUES: [
        {
            text: "DURATION",
            value: "duration"
        },
        {
            text: "VALUE",
            value: "value"
        }
    ],
    HERO_SPELLS: [
        {
            text: 'Freeze',
            value: 0
        },
        {
            text: "Delivery",
            value: 1
        },
        {
            text: "Speed Up",
            value: 2
        },
        {
            text: "Greed",
            value: 3
        },
        {
            text: "Stone Gaze",
            value: 4
        },
        {
            text: "Scorched Earth",
            value: 5
        },
        {
            text: "Siren Call",
            value: 6
        },
        {
            text: "Spell 7",
            value: 7
        },
        {
            text: "Spell 8",
            value: 8
        },
        {
            text: "Spell 9",
            value: 9
        },
        {
            text: "Spell 10",
            value: 10
        }
    ]
}